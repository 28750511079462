<script>
import BaseAsidePage from '/~/components/base/aside-page/base-aside-page.vue'
import CheckoutAcknowledgement from '/~/views/checkout.v2/components/checkout/acknowledgement.vue'
import SummaryItemsMobile from '/~/views/checkout.v2/components/checkout/summary/summary-items.mobile.vue'
import SummaryTotal from '/~/views/checkout.v2/components/checkout/summary/summary-total.vue'
import CheckoutDesktop from './checkout.desktop.vue'

export default {
  name: 'checkout-mobile',
  components: {
    BaseAsidePage,
    SummaryTotal,
    SummaryItemsMobile,
    CheckoutAcknowledgement,
  },
  extends: CheckoutDesktop,
  setup() {
    return {
      ...CheckoutDesktop.setup?.(...arguments),
    }
  },
}
</script>

<template>
  <base-aside-page
    title="Payment options"
    no-padding
    shadow
    :back="redirect"
    class="flex flex-col"
  >
    <div class="w-full flex-1 overflow-auto">
      <base-loader
        v-if="payment.isQuickBuyInitializing"
        size="xl"
        class="h-full w-full"
      />

      <div v-else class="mx-auto flex w-full max-w-xl flex-col px-4">
        <checkout-select-address
          v-if="isUserAddressEnabled"
          :show-addresses="payment.isAddressSelectionAllowed"
        />
        <payment-methods-list class="mt-5" />
        <checkout-coupons
          v-if="payment.isCouponsAllowed"
          :flow-type="currentFlowType"
        />
        <summary-items-mobile class="py-4" />
        <summary-total />
      </div>
    </div>

    <div class="mx-auto w-full max-w-xl px-4">
      <checkout-acknowledgement />

      <div class="w-full">
        <message-panel
          v-show="showAdditionalPaymentMsg && additionalPaymentMsg.show"
          class="w-full py-4"
          type="warning"
          icon="plain/alert"
          dont-close
        >
          {{ additionalPaymentMsg.message }}
        </message-panel>
      </div>

      <div class="flex w-full justify-center gap-2.5 py-[15px] sm:py-5">
        <base-button look="outlined-color" class="flex-1" @click="onCancel()">
          Cancel
        </base-button>

        <base-button
          v-if="isUserAddressEnabled && addresses.length === 0"
          class="flex-1"
          :loading="processing"
          @click="showAddAddress"
        >
          Add address
        </base-button>

        <base-button
          v-else
          class="flex-1"
          :disabled="!payment.readyToPay || !payment.isTermsAccepted"
          :loading="payment.loadingFees || processing"
          @click="onSubmit"
        >
          {{ payment.isScheduledPayment ? 'Schedule' : 'Pay' }}
        </base-button>
      </div>
    </div>
  </base-aside-page>
</template>
